import React from "react";
import "../styles/SelectionProcess.scss";
import originalityImg from "../assets/Originality and Innovation.svg";
import ResearchImg from "../assets/Research Methodology.svg";
import QualityImg from "../assets/Quality of Writing.svg";
import practicalImg from "../assets/Practical implications.svg";
import knowledgeImg from "../assets/Contribution to existing knowledge.svg";

function SelectionProcess() {
  return (
    <>
      <div className="process-container">
        <h1 className="uppercase"> Selection Process</h1>
        <div className="image-grid">
          <img src={QualityImg} alt="Quality of Writing" />
          <img src={originalityImg} alt="Originality and Innovation" />
          <img src={practicalImg} alt="Practical Implications" />
          <img src={ResearchImg} alt="Research Methodology" />
        </div>
        <div className="image-solo">
          <img src={knowledgeImg} alt="ResearchImg" />
        </div>
      </div>
    </>
  );
}

export default SelectionProcess;
