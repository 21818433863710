import "../styles/Footer.scss";
import PENLogo from "../assets/PENWhite.svg";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export const Footer = () => {
  return (
    <section className="footer">
      <div className="footer-container">
        <div className="pen-details">
          <div className="pen-header">
            <img src={PENLogo} alt="" />
          </div>
          <div className="pen-title">
            POPULUS EMPOWERMENT NETWORK PRIVATE LIMITED
          </div>
          <div className="pen-description">
            Move the needle at national, state and local levels with the right
            voices and the right strategy
          </div>
          <div className="social-media-hendles">
            <a href="https://www.facebook.com/PEN.Offl/" target={"_blank"}>
              <li className="pen-social-media">
                <svg
                  className="social-media-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="67"
                  height="68"
                  viewBox="0 0 67 68"
                >
                  <defs>
                    <filter
                      id="Ellipse_15"
                      x="0"
                      y="0"
                      width="67"
                      height="68"
                      filterUnits="userSpaceOnUse"
                    >
                      <feOffset dy="3" />
                      <feGaussianBlur stdDeviation="3" result="blur" />
                      <feFlood flood-opacity="0.161" />
                      <feComposite operator="in" in2="blur" />
                      <feComposite in="SourceGraphic" />
                    </filter>
                  </defs>
                  <g
                    id="_001-facebook"
                    data-name="001-facebook"
                    transform="translate(8.679 6.386)"
                  >
                    <g
                      transform="matrix(1, 0, 0, 1, -8.68, -6.39)"
                      filter="url(#Ellipse_15)"
                    >
                      <ellipse
                        id="Ellipse_15-2"
                        data-name="Ellipse 15"
                        cx="24.5"
                        cy="25"
                        rx="24.5"
                        ry="25"
                        transform="translate(9 6)"
                        fill="#3b5998"
                      />
                    </g>
                    <path
                      id="Path_16"
                      data-name="Path 16"
                      d="M51.971,37.732H47.595V53.763h-6.63V37.732H37.812V32.1h3.153V28.453c0-2.607,1.238-6.69,6.689-6.69l4.911.021v5.469H49A1.35,1.35,0,0,0,47.6,28.788V32.1H52.55Z"
                      transform="translate(-21.174 -12.124)"
                      fill="#fff"
                    />
                  </g>
                </svg>
              </li>
            </a>
            <a
              href="https://twitter.com/PEN_Offl"
              target="_blank"
              rel="noopener noreferrer"
            >
              <li className="pen-social-media">
                <svg
                  className="social-media-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="67"
                  height="68"
                  viewBox="0 0 67 68"
                >
                  <defs>
                    <filter
                      id="Path_5317"
                      x="0"
                      y="0"
                      width="67"
                      height="68"
                      filterUnits="userSpaceOnUse"
                    >
                      <feOffset dy="3" />
                      <feGaussianBlur stdDeviation="3" result="blur" />
                      <feFlood flood-opacity="0.161" />
                      <feComposite operator="in" in2="blur" />
                      <feComposite in="SourceGraphic" />
                    </filter>
                  </defs>
                  <g
                    id="_002-twitter"
                    data-name="002-twitter"
                    transform="translate(9.028 6.386)"
                  >
                    <g
                      transform="matrix(1, 0, 0, 1, -9.03, -6.39)"
                      filter="url(#Path_5317)"
                    >
                      <path
                        id="Path_5317-2"
                        data-name="Path 5317"
                        d="M24.5,0A24.754,24.754,0,0,1,49,25,24.754,24.754,0,0,1,24.5,50,24.754,24.754,0,0,1,0,25,24.754,24.754,0,0,1,24.5,0Z"
                        transform="translate(9 6)"
                        fill="#55acee"
                      />
                    </g>
                    <g
                      id="Group_39"
                      data-name="Group 39"
                      transform="translate(11.059 14.975)"
                    >
                      <path
                        id="Path_17"
                        data-name="Path 17"
                        d="M53.739,36.783a11.722,11.722,0,0,1-3.375.924,5.891,5.891,0,0,0,2.584-3.25,11.761,11.761,0,0,1-3.731,1.425,5.88,5.88,0,0,0-10.011,5.359A16.677,16.677,0,0,1,27.1,35.1a5.881,5.881,0,0,0,1.818,7.844,5.834,5.834,0,0,1-2.661-.736c0,.025,0,.05,0,.074a5.878,5.878,0,0,0,4.713,5.76,5.9,5.9,0,0,1-2.653.1,5.881,5.881,0,0,0,5.488,4.08,11.86,11.86,0,0,1-8.7,2.433,16.618,16.618,0,0,0,9.005,2.64c10.806,0,16.716-8.952,16.716-16.716,0-.255-.006-.508-.017-.76a11.914,11.914,0,0,0,2.932-3.041Z"
                        transform="translate(-25.103 -34.028)"
                        fill="#f1f2f2"
                      />
                    </g>
                  </g>
                </svg>
              </li>
            </a>
            <a href="https://www.instagram.com/PEN.Offl/" target={"_blank"}>
              <li className="pen-social-media">
                <svg
                  className="social-media-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="67"
                  height="68"
                  viewBox="0 0 67 68"
                >
                  <defs>
                    <filter
                      id="XMLID_30_"
                      x="0"
                      y="0"
                      width="67"
                      height="68"
                      filterUnits="userSpaceOnUse"
                    >
                      <feOffset dy="3" />
                      <feGaussianBlur stdDeviation="3" result="blur" />
                      <feFlood flood-opacity="0.161" />
                      <feComposite operator="in" in2="blur" />
                      <feComposite in="SourceGraphic" />
                    </filter>
                  </defs>
                  <g
                    id="Group_15053"
                    data-name="Group 15053"
                    transform="translate(-6001 -6901.458)"
                  >
                    <g
                      id="_004-google-plus"
                      data-name="004-google-plus"
                      transform="translate(6009.969 6907.844)"
                    >
                      <g
                        id="Group_40"
                        data-name="Group 40"
                        transform="translate(0 0)"
                      >
                        <g
                          transform="matrix(1, 0, 0, 1, -8.97, -6.39)"
                          filter="url(#XMLID_30_)"
                        >
                          <ellipse
                            id="XMLID_30_2"
                            data-name="XMLID_30_"
                            cx="24.5"
                            cy="25"
                            rx="24.5"
                            ry="25"
                            transform="translate(9 6)"
                            fill="#d5296b"
                          />
                        </g>
                      </g>
                    </g>
                    <path
                      id="Icon_awesome-instagram"
                      data-name="Icon awesome-instagram"
                      d="M13.378,8.756a6.86,6.86,0,1,0,6.86,6.86A6.849,6.849,0,0,0,13.378,8.756Zm0,11.319a4.46,4.46,0,1,1,4.46-4.46,4.468,4.468,0,0,1-4.46,4.46Zm8.74-11.6a1.6,1.6,0,1,1-1.6-1.6A1.6,1.6,0,0,1,22.118,8.475ZM26.662,10.1A7.918,7.918,0,0,0,24.5,4.493a7.97,7.97,0,0,0-5.606-2.161c-2.209-.125-8.83-.125-11.039,0A7.959,7.959,0,0,0,2.25,4.487,7.944,7.944,0,0,0,.089,10.093c-.125,2.209-.125,8.83,0,11.039A7.918,7.918,0,0,0,2.25,26.737,7.98,7.98,0,0,0,7.856,28.9c2.209.125,8.83.125,11.039,0A7.918,7.918,0,0,0,24.5,26.737a7.97,7.97,0,0,0,2.161-5.606c.125-2.209.125-8.824,0-11.033ZM23.808,23.5a4.515,4.515,0,0,1-2.543,2.543c-1.761.7-5.94.537-7.886.537s-6.131.155-7.886-.537A4.515,4.515,0,0,1,2.948,23.5c-.7-1.761-.537-5.94-.537-7.886s-.155-6.131.537-7.886A4.515,4.515,0,0,1,5.492,5.185c1.761-.7,5.94-.537,7.886-.537s6.131-.155,7.886.537a4.515,4.515,0,0,1,2.543,2.543c.7,1.761.537,5.94.537,7.886S24.506,21.746,23.808,23.5Z"
                      transform="translate(6021.23 6916.864)"
                      fill="#fff"
                    />
                  </g>
                </svg>
              </li>
            </a>
            <a
              href="https://www.linkedin.com/company/Populus-Empowerment-Network"
              target="_blank"
              rel="noopener noreferrer"
            >
              <li className="pen-social-media">
                <svg
                  className="social-media-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="74.757"
                  height="74.757"
                  viewBox="0 0 74.757 74.757"
                >
                  <defs>
                    <filter
                      id="Path_5318"
                      x="0"
                      y="0"
                      width="74.757"
                      height="74.757"
                      filterUnits="userSpaceOnUse"
                    >
                      <feOffset dy="3" />
                      <feGaussianBlur stdDeviation="3" result="blur" />
                      <feFlood flood-opacity="0.161" />
                      <feComposite operator="in" in2="blur" />
                      <feComposite in="SourceGraphic" />
                    </filter>
                  </defs>
                  <g
                    id="Group_15052"
                    data-name="Group 15052"
                    transform="translate(-207.829 -50.756)"
                  >
                    <g
                      transform="matrix(1, 0, 0, 1, 207.83, 50.76)"
                      filter="url(#Path_5318)"
                    >
                      <path
                        id="Path_5318-2"
                        data-name="Path 5318"
                        d="M25.012,0A25.012,25.012,0,1,1,0,25.012,25.012,25.012,0,0,1,25.012,0Z"
                        transform="matrix(0.99, -0.15, 0.15, 0.99, 9, 13.26)"
                        fill="#0054a2"
                      />
                    </g>
                    <g
                      id="Group_15014"
                      data-name="Group 15014"
                      transform="translate(232.933 72.888)"
                    >
                      <path
                        id="Path_5315"
                        data-name="Path 5315"
                        d="M249.667,97.377h5.094v16.363h-5.094Zm2.548-8.131a2.949,2.949,0,1,1-2.952,2.946,2.949,2.949,0,0,1,2.952-2.946"
                        transform="translate(-249.262 -89.246)"
                        fill="#fff"
                      />
                      <path
                        id="Path_5316"
                        data-name="Path 5316"
                        d="M266.763,105.21h4.878v2.238h.067a5.346,5.346,0,0,1,4.816-2.644c5.149,0,6.1,3.387,6.1,7.793v8.974h-5.082v-7.955c0-1.9-.037-4.339-2.643-4.339-2.647,0-3.052,2.067-3.052,4.2v8.094h-5.083Z"
                        transform="translate(-258.073 -97.08)"
                        fill="#fff"
                      />
                    </g>
                  </g>
                </svg>
              </li>
            </a>
          </div>
        </div>
        <div className="pen-site-details">
          <div className="site-detail">PEN INDIA</div>
          <nav className="pages-list">
            <li>
              <HashLink className="navbar_link_button privacy-policy" to={"/"}>
                Home
              </HashLink>
            </li>
            <li>
              <HashLink
                className="navbar_link_button privacy-policy"
                to={"/#aboutus_container"}
              >
                About Us
              </HashLink>
            </li>
            <li>
              <HashLink
                className="navbar_link_button privacy-policy"
                to={"/#services_container"}
              >
                Services
              </HashLink>
            </li>
            <li>
              <HashLink
                className="navbar_link_button privacy-policy"
                to={"/scholar-awards"}
              >
                Scholar Awards
              </HashLink>
            </li>
            <li>
              <HashLink
                className="navbar_link_button privacy-policy"
                to={"/#joinus_container"}
              >
                Join Us
              </HashLink>
            </li>
            <li>
              <HashLink
                className="navbar_link_button privacy-policy"
                to={"/#contactus_container"}
              >
                Talk to us
              </HashLink>
            </li>
            <li>
              <Link className="privacy-policy" to="/privacy-policy">
                Privacy Policy
              </Link>
            </li>
          </nav>
        </div>
        <div className="pen-contact-details">
          <div className="contact-header">Contact Info</div>
          <nav className="pages-list">
            <li>
              <span className="mail-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25.37"
                  height="20.068"
                  viewBox="0 0 25.37 20.068"
                >
                  <g id="mail" transform="translate(1.393 1)">
                    <path
                      id="Path_25"
                      data-name="Path 25"
                      d="M4.258,4H22.326a2.265,2.265,0,0,1,2.258,2.258V19.809a2.265,2.265,0,0,1-2.258,2.258H4.258A2.265,2.265,0,0,1,2,19.809V6.258A2.265,2.265,0,0,1,4.258,4Z"
                      transform="translate(-2 -4)"
                      fill="none"
                      stroke="#ffeaad"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <path
                      id="Path_26"
                      data-name="Path 26"
                      d="M24.585,6,13.292,13.9,2,6"
                      transform="translate(-2 -3.742)"
                      fill="none"
                      stroke="#ffeaad"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </g>
                </svg>
              </span>
              contact@thepenindia.com
            </li>
            <li>
              <span className="mail-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24.586"
                  height="24.632"
                  viewBox="0 0 24.586 24.632"
                >
                  <path
                    id="phone"
                    d="M24.7,18.943v3.407a2.271,2.271,0,0,1-2.476,2.271,22.473,22.473,0,0,1-9.8-3.486,22.143,22.143,0,0,1-6.813-6.813A22.473,22.473,0,0,1,2.121,4.476,2.271,2.271,0,0,1,4.381,2H7.788a2.271,2.271,0,0,1,2.271,1.953,14.581,14.581,0,0,0,.795,3.191,2.271,2.271,0,0,1-.511,2.4L8.9,10.982A18.169,18.169,0,0,0,15.714,17.8l1.442-1.442a2.271,2.271,0,0,1,2.4-.511,14.581,14.581,0,0,0,3.191.795A2.271,2.271,0,0,1,24.7,18.943Z"
                    transform="translate(-1.111 -1)"
                    fill="none"
                    stroke="#ffeaad"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                </svg>
              </span>
              044-45262993, 7200498616
            </li>
            <li className="text">
              <span className="mail-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.481"
                  height="24.588"
                  viewBox="0 0 20.481 24.588"
                >
                  <g id="map-pin" transform="translate(1 1)">
                    <path
                      id="Path_27"
                      data-name="Path 27"
                      d="M21.481,10.241c0,7.187-9.241,13.348-9.241,13.348S3,17.428,3,10.241a9.241,9.241,0,1,1,18.481,0Z"
                      transform="translate(-3 -1)"
                      fill="none"
                      stroke="#ffeaad"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <circle
                      id="Ellipse_17"
                      data-name="Ellipse 17"
                      cx="3.519"
                      cy="3.519"
                      r="3.519"
                      transform="translate(5.631 5.695)"
                      fill="none"
                      stroke="#ffeaad"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </g>
                </svg>
              </span>
              OLD NO.38, NEW NO 22, Oliver Road/Musiri Subramaniam Road,
              Mylapore, Chennai, TamilNadu - 600004
            </li>
          </nav>
        </div>
      </div>
    </section>
  );
};
