import React, { useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import "../styles/Career.scss";

interface FormData {
  position: string;
  email: string;
  cv: File | any;
  name: string;
}

interface FormErrors {
  position: string;
  email: string;
  cv: string;
  name: string;
}
export const Career = () => {
  const OpeningList = [
    {
      id: 1,
      title: "Field Associate",
      experience: "0 - 5",
      jobDescription:
        "Basic proficiency in documentation is required. Should be able to write & speak in Tamil. Should be ready to travel daily. Must have a valid driving license and access to a personal bike.",
    },
  ];

  const [fileName, setFileName] = useState<string | null>(null);
  const [formData, setFormData] = useState<FormData>({
    position: "",
    email: "",
    cv: null,
    name: "",
  });
  const [alertMessage, setAlertMessage] = useState("");
  const [alertColor, setAlertColor] = useState("");
  const [selectedJob, setSelectedJob] = useState<any>(OpeningList[0]);
  const [isLoading, setIsLoading] = useState(false);

  const [formErrors, setFormErrors] = useState<FormErrors>({
    position: "",
    email: "",
    cv: "",
    name: "",
  });

  const validateForm = () => {
    const errors: FormErrors = {
      name: !formData.name.trim()
        ? "Name is required"
        : !/^[A-Za-z\s]+$/.test(formData.name)
        ? "Name should only contain letters and spaces"
        : "",
      email: !formData.email.trim()
        ? "Email is required"
        : !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
            formData.email
          )
        ? "Please enter a valid email"
        : "",
      position: !formData.position ? "Position is required" : "",
      cv: !formData.cv ? "CV is required" : "",
    };

    setFormErrors(errors);
    return !Object.values(errors).some((error) => error !== "");
  };

  const handleJobClick = (job: any) => {
    setSelectedJob(job);
  };

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    if (name === "name" && value && !/^[A-Za-z\s]*$/.test(value)) {
      return;
    }
    setFormData((prev) => ({ ...prev, [name]: value }));
    // Clear error when user starts typing
    setFormErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleCVUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setFileName(file.name);
    }
    setFormData((prevState) => ({
      ...prevState,
      cv: file || null,
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!validateForm()) {
      setAlertMessage("");
      setAlertColor("red");
      return;
    }

    setIsLoading(true);
    setAlertMessage("");

    const formDataToSend = new FormData();
    formDataToSend.append("application_type", formData.position);
    formDataToSend.append("applicant_email", formData.email);
    formDataToSend.append("file", formData.cv);
    formDataToSend.append("applicant_name", formData.name);

    try {
      await new Promise((resolve) => setTimeout(resolve, 10000));
      const response = await fetch(
        "https://stage-emailtrigger.azurewebsites.net/api/trigger-queue?code=FffeiWXEBEU8dDq4GbkjkH1mqGLu7uzfs4s8RHNogYpFAzFuWBFzTg==",
        {
          method: "POST",
          body: formDataToSend,
        }
      );

      if (response.status === 200) {
        setAlertMessage("Thanks for submitting your application");
        setAlertColor("green");
      } else {
        setAlertMessage("");
        setAlertColor("red");
      }
    } catch (error) {
      console.error(error);
      setAlertColor("red");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="career_joinus_container d-flex justify-content-center align-items-center">
      <div className="career_main_left_div">
        <div className="career_title_div">Career</div>
        <div className="career_open_positions_div">
          <div className="positions_table">
            <div className="positions_tabel_title">Current Openings</div>
            <div className="positions_table_data_div">
              {OpeningList.map((item: any) => (
                <div
                  key={item.id}
                  onClick={() => handleJobClick(item)}
                  className={`positions_name_div ${
                    selectedJob && selectedJob.id === item.id ? "selected" : ""
                  }`}
                >
                  {item.title}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="career_main_right_div">
        <div className="career_main_image_div">
          {selectedJob && (
            <div className="job-details">
              <h1 className="job_title">{selectedJob?.title}</h1>
              <div className="job_experience">
                <span style={{ fontWeight: "bolder" }}>Experience Level:</span>{" "}
                {selectedJob?.experience}
              </div>
              <div className="job_description">
                <span style={{ fontWeight: "bolder" }}>Description:</span>{" "}
                {selectedJob?.jobDescription}
              </div>
            </div>
          )}
        </div>
        <div className="career_decor_div"></div>
      </div>

      <div className="career_form_div">
        <div className="career_form_title">
          For Internships and Fellowships opportunities please share your resume
        </div>

        <div className="career_form_main_div">
          <form
            onSubmit={handleSubmit}
            method="POST"
            className="form_main_component"
          >
            <div className="inputbox_and_label_div">
              <div className="label_div">Name *</div>
              <div className="input_div">
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className={`main_input_div ${formErrors.name ? "error" : ""}`}
                />
                {formErrors.name && (
                  <div className="error-message">{formErrors.name}</div>
                )}
              </div>
            </div>

            <div className="inputbox_and_label_div">
              <div className="label_div">Email ID *</div>
              <div className="input_div">
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className={`main_input_div ${
                    formErrors.email ? "error" : ""
                  }`}
                />
                {formErrors.email && (
                  <div className="error-message">{formErrors.email}</div>
                )}
              </div>
            </div>

            <div className="inputbox_and_label_div">
              <div className="label_div">Apply For Positions *</div>
              <div className="input_div">
                <Select
                  name="position"
                  value={formData.position}
                  onChange={handleInputChange}
                  className={`main_input_div position ${
                    formErrors.position ? "error" : ""
                  }`}
                >
                  <MenuItem value="" disabled>
                    Select a position
                  </MenuItem>
                  {OpeningList.map((item: any) => (
                    <MenuItem key={item.id} value={item.title}>
                      {item.title}
                    </MenuItem>
                  ))}
                </Select>
                {formErrors.position && (
                  <div className="error-message">{formErrors.position}</div>
                )}
              </div>
            </div>

            <div className="inputbox_and_label_div">
              <div className="label_div">Upload Your CV *</div>
              <div className="input_div">
                <label htmlFor="cv-upload" className="upload-icon-label">
                  <FileUploadIcon className="upload-icon ps-2" />
                  {fileName && (
                    <div className="file-name" title={fileName}>
                      {fileName.toString().slice(0, 15).concat("...")}
                    </div>
                  )}
                </label>
                <input
                  type="file"
                  accept=".pdf,.doc,.docx"
                  id="cv-upload"
                  onChange={handleCVUpload}
                  className={`main_input_div_file_upload ${
                    formErrors.cv ? "error" : ""
                  }`}
                />
                {formErrors.cv && (
                  <div className="error-message">{formErrors.cv}</div>
                )}
              </div>
            </div>

            <div className="career_inputbox_submit_button">
              <button
                type="submit"
                className={`submit_button ${isLoading ? "loading" : ""}`}
                disabled={isLoading}
              >
                <span>Submit</span>
                {isLoading && <div className="loading-layer"></div>}
              </button>
            </div>
          </form>

          <div className={`alertmessage-career ${alertColor}`}>
            {alertMessage && (
              <div className="alert_message-career">{alertMessage}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
