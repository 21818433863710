import React from "react";
import "../styles/Awards.scss";
import GenderStudiesImg from "../assets/Gender studies.png";
import SocialScienceImg from "../assets/category icon.png";
import YouthDevelopmentImg from "../assets/Youth Development.png";
import ScholorLogo from "../assets/Scholar awards.png";

function ScholarAwards() {
  return (
    <div className="scholar-awards">
      <div className="scholar-awards-logo-container">
        <img className="scholars-logo" src={ScholorLogo} alt="" />
      </div>
      <div className="scholar-content">
        <p>
          <span className="bolder">PEN Scholar Awards</span> aims to{" "}
          <span className="bolder">reward</span> the outstanding Ph.D. Theses in
          the fields of
        </p>

        <div className="image-section">
          <img src={GenderStudiesImg} alt="Gender Studies" />
          <img src={SocialScienceImg} alt="Social Science" />
          <img
            className="youth-image"
            src={YouthDevelopmentImg}
            alt="Youth Development"
          />
        </div>
      </div>
    </div>
  );
}

export default ScholarAwards;
