import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import AcknowledgePage from "./AcknowledgePage";
import Eligibility from "./Eligibility";
import ProcedurePage from "./ProcedurePage";
import ScholarAwards from "./ScholarAwards";
import SelectionProcess from "./SelectionProcess";
import TimeLine from "./TimeLine";
import Header from "./header";
import { Footer } from "./Footer";

const Scholars = () => {
  const location = useLocation();
  const [activeSection, setActiveSection] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll("div[id]");
      const scrollPosition = window.scrollY + window.innerHeight / 2;

      sections.forEach((section:any) => {
        const sectionTop = section.getBoundingClientRect().top + window.scrollY;
        const sectionBottom = sectionTop + section.offsetHeight;
        const sectionId = section.id;

        if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
          setActiveSection(sectionId);
          window.history.replaceState(null, "", ``);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="scholar-container">
      <Helmet>
        <title>PEN Scholar Awards</title>
        <meta name="title" content="PEN Scholar Awards" />
        <meta
          name="description"
          content="Discover the Populus Empowerment Network's Scholar Awards Initiative, designed to support and recognize outstanding students in their academic pursuits."
        />
      </Helmet>
      <Header activeSection={activeSection} />
      <div id="scholar-awards" className="section">
        <ScholarAwards />
      </div>
      <div id="acknowledge" className="section">
        <AcknowledgePage />
      </div>
      <div id="eligibility" className="section">
        <Eligibility />
      </div>
      <div id="selection" className="section">
        <SelectionProcess />
      </div>
      <div id="timeline" className="section">
        <TimeLine />
      </div>
      <div id="footer" className="section">
        <Footer />
      </div>
    </div>
  );
};

export default Scholars;