import React, { useState, useEffect } from "react";
import "./styles/Main.scss";
import Header from "./componenets/header";
import { About } from "./componenets/About";
import { Services } from "./componenets/Services";
import { Contact } from "./componenets/Contact";
import { Footer } from "./componenets/Footer";
import Carousel from "./componenets/Carousel";
import { JoinUs } from "./componenets/JoinUs";
import { Career } from "./componenets/Career";

const Main: React.FC = () => {
  const [activeSection, setActiveSection] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      // Get all sections including both .section and .section_service
      const sections = document.querySelectorAll(".section, .section_service");
      const scrollPosition = window.scrollY + window.innerHeight / 3; // Changed to /3 for earlier detection

      let currentSection = "";
      
      sections.forEach((section: any) => {
        const sectionTop = section.offsetTop - 100; // Added offset for earlier detection
        const sectionBottom = sectionTop + section.offsetHeight;
        const sectionId = section.id;

        if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
          currentSection = sectionId;
        }
      });

      if (currentSection !== activeSection) {
        setActiveSection(currentSection);
        window.history.replaceState(null, "", `#${currentSection}`);
      }
    };

    const debouncedHandleScroll = debounce(handleScroll, 100); // Add debouncing

    window.addEventListener("scroll", debouncedHandleScroll);
    handleScroll();
    
    return () => window.removeEventListener("scroll", debouncedHandleScroll);
  }, [activeSection]);

  function debounce(func: Function, wait: number) {
    let timeout: NodeJS.Timeout;
    return function executedFunction(...args: any[]) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  useEffect(() => {
    const hash = window.location.hash.slice(1);
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, []);

  const Content = () => (
    <>
      <div className="section" id="carousel">
        <div className="top_carousel_main_div">
          <Carousel />
        </div>
      </div>
      <div className="section" id="aboutus_container">
        <div className="aboutus_main_component_div">
          <About />
        </div>
      </div>
      <div className="section_service" id="services_container">
        <div className="services_main_component_div">
          <Services />
        </div>
      </div>
      <div className="section" id="joinus_container">
        <div className="join_us_main_component_div">
          <JoinUs />
        </div>
      </div>
      <div className="section" id="career">
        <div className="career_main_component_div">
          <Career />
        </div>
      </div>
      <div className="section" id="contactus_container">
        <div className="contactus_main_component_div">
          <Contact />
        </div>
      </div>
      <div className="section_footer" id="footer_container">
        <div className="main_footer_div">
          <Footer />
        </div>
      </div>
    </>
  );

  return (
    <div className="main-container">
      <Header activeSection={activeSection} />
      <Content />
    </div>
  );
};

export default Main;