import React from "react";
import "../styles/eligibility.scss";
import eligibilitiyImg from "../assets/Eligibility.png";

function Eligibility() {
  return (
    <div className="eligibility-container">
      <h1 className="eligibility-header">Eligibility</h1>
      <div className="eligibility-content">
        <div className="eligibility-image">
          <img src={eligibilitiyImg} alt="Eligibility" />
        </div>
        <div className="eligibility-details">
          <div>Area of Study - Social Sciences, Gender Studies, and Youth Development.</div>
          <div>The thesis should have been submitted from 1st June 2023 – 30th May 2024.</div>
          <div>The thesis should have been submitted to a University in Tamil Nadu/Puducherry.</div>
        </div>
      </div>
    </div>
  );
}

export default Eligibility;
