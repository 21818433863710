import { styled } from "@mui/material";

export const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "95%", sm: "90%", md: "80%" },
  height: "80%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "30px",
  zIndex: 9999,
  p: 4,
  padding: { xs: 0, sm: 2, md: 4 },
  overflowX: "hidden !important",
  outline: 0,
};
export const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export const isValidEmail = (email: string) =>
  /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

export const validateField = (name: string, value: string) => {
  switch (name) {
    case "name":
      return value.trim() ? "" : "Name is required.";
    case "email":
      return /^\S+@\S+\.\S+$/.test(value) ? "" : "Please enter a valid email.";
    case "phoneNumber":
      return /^\d{10}$/.test(value) ? "" : "Mobile number must be 10 digits.";
    case "age":
      return parseInt(value) > 0 ? "" : "Age must be a positive number.";
    case "gender":
      return value ? "" : "Gender is required.";
    case "isThesisSubmitted":
      return value ? "" : "This field is required.";
    case "yearOfSubmission":
      return value ? "" : "Year of submission is required.";
    case "nameOfInstitution":
      return value.trim() ? "" : "Institution name is required.";
    case "placeOfInstitution":
      return value ? "" : "Place of institution is required.";
    case "subject":
      return value ? "" : "Subject is required.";
    case "topic":
      return value.trim() ? "" : "Thesis topic is required.";
    case "nameOfPhdSupervisor":
      return value.trim() ? "" : "Supervisor's name is required.";
    case "emailOfPhdSupervisor":
      return /^\S+@\S+\.\S+$/.test(value) ? "" : "Please enter a valid email.";
    case "contributors":
      return value.trim() ? "" : "Contributors are required.";
    case "publicationDerived":
      return value.trim() ? "" : "Publications are required.";
    case "initial":
      return value.trim() ? "" : "Initials are required.";
    default:
      return "";
  }
};
