import React, { useState } from "react";
import "../styles/TimeLine.scss";
import PrizeImg from "./../assets/Prize Text Box.svg"
import PopupModal from "./PopupModal";
function TimeLine() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="timeline-container">
        <div>
          <div className="timeline-text">
            Call for <br /> submission
          </div>
          <div className="timeline-box">23.12.2024</div>
        </div>
        <div className="arrow-container">
          <div className="arrow-line"></div>
          <svg
            className="arrow-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
          </svg>
        </div>
        <div>
          <div className="timeline-text">
            Deadline to <br /> Submit Thesis
          </div>
          <div className="timeline-box">31.01.2025</div>
        </div>
        <div className="arrow-container">
          <div className="arrow-line"></div>
          <svg
            className="arrow-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
          </svg>
        </div>
        <div>
          <div className="timeline-text">
            Announcement
            <br /> of Result
          </div>
          <div className="timeline-box">28.02.2025</div>
        </div>
      </div>
      <div className="prize-image">
        <img src={PrizeImg} alt="Prize image" />
      </div>
      <div className="header-container">
        <div className="goal-content">
          <h1>Our Goal</h1>
          <p>
            Many of the best Ph.D. Theses go unnoticed and end up in a sleepy
            corner of a University Library or as a hard-to-retrieve file in an
            online repository. But, for a <b>society to grow and flourish</b>,
            good ideas need to be<b> recognized</b> and in the long run, they
            need to be
            <b className="bolder"> implemented</b> too.
            <br />
            <b className="bolder">PEN Scholar Awards</b> is an initiative to{" "}
            <b className="bolder">reward the hard work</b> and the thought
            process that goes behind creating an excellent thesis.
            <br />
            This award will <b className="bolder">inspire more</b> researchers
            to produce <b className="bolder">good quality research</b> in an{" "}
            <b className="bolder">ethical way</b>.
          </p>
        </div>
      </div>
      <div className="button-container">
        <button className="apply-button" onClick={openModal}>
          Apply
        </button>
      </div>
      <PopupModal isOpen={isModalOpen} onClose={closeModal} />
    </>
  );
}

export default TimeLine;
