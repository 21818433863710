import React, { useEffect, useState, useRef } from "react";
import "../styles/Joinus.scss";
import test_image from "../assets/joinusoriginal.png";
const vacation_logo: string = require("../assets/vacation.svg").default;
const training_logo: string = require("../assets/training_icon.svg").default;
const care_logo: string = require("../assets/testthree.svg").default;
const joinedhands_logo: string = require("../assets/testfour.svg").default;
const rewarded_logo: string = require("../assets/testfive.svg").default;
const medal_logo: string = require("../assets/testone.svg").default;

export const JoinUs = () => {
  const [title, setTitle] = useState("Diversity & Inclusion");
  const [text, setText] = useState(
    "A workplace culture that welcomes and respects all individuals."
  );

  const handleSubCircleClick = (title: string, text: string) => {
    setTitle(title);
    setText(text);
  };

  return (
    <div className="landingpage_joinus_container  d-flex justify-content-center align-items-center">
      <div className="joinus_decoration_div"></div>
      <div className="joinus_content_div">
        <div className="joinus_content_leftdiv">
          <div className="join_us_content_div d-flex justify-content-between">
            <h1 className="joinus_title_div d-flex justify-content-start align-items-center ps-5">
              Join Us
            </h1>
            <div className="join_us_text d-flex justify-content-center align-items-center ps-5">
              At PEN, we value and prioritize the well-being and growth of our
              team members. As part of our commitment to providing a supportive
              work environment, we offer a range of benefits and perks that
              include:
            </div>
          </div>

          <div className="joinus_left_component_info_div">
            <div className="joinus_circle_deco_div"></div>
            <div className="joinus_main_circle_div">
              <div className="joinus_main_circle">
                <img
                  src={test_image}
                  alt="JOIN US IMAGE"
                  className="joinus_main_image"
                />

                <div
                  className="join_us_text_inside_circle"
                  onClick={() =>
                    handleSubCircleClick(
                      "Diversity & Inclusion",
                      "A workplace culture that welcomes and respects all individuals."
                    )
                  }
                  onMouseOver={() => {
                    handleSubCircleClick(
                      "Diversity & Inclusion",
                      "A workplace culture that welcomes and respects all individuals."
                    );
                  }}
                >
                  <div className="title_div d-flex justify-content-center align-items-center">
                    {title}
                  </div>
                  <div className="text_div d-flex justify-content-center align-items-start pt-4 text-center">
                    {text}
                  </div>
                </div>

                <div
                  className="joinus_sub_circleone"
                  onClick={() =>
                    handleSubCircleClick(
                      "Training Programs",
                      "Extensive training programs for professional development"
                    )
                  }
                  onMouseOver={() => {
                    handleSubCircleClick(
                      "Training Programs",
                      "Extensive training programs for professional development"
                    );
                  }}
                >
                  <div className="image_container_joinus">
                    <img
                      src={training_logo}
                      alt="JOIN US IMAGE"
                      className="joinus_sub_image"
                    />
                  </div>
                </div>
                <div
                  className="joinus_sub_circletwo"
                  onClick={() =>
                    handleSubCircleClick(
                      "Diversity & Inclusion",
                      "A workplace culture that welcomes and respects all individuals."
                    )
                  }
                  onMouseOver={() => {
                    handleSubCircleClick(
                      "Diversity & Inclusion",
                      "A workplace culture that welcomes and respects all individuals."
                    );
                  }}
                >
                  <div className="image_container_joinus">
                    <img
                      src={joinedhands_logo}
                      alt="JOIN US IMAGE"
                      className="joinus_sub_image"
                    />
                  </div>
                </div>
                <div
                  className="joinus_sub_circlethree"
                  onClick={() =>
                    handleSubCircleClick(
                      "Rewards & Recognitions",
                      "Rewards and recognition programs to celebrate individual and team’s achievements"
                    )
                  }
                  onMouseOver={() => {
                    handleSubCircleClick(
                      "Rewards & Recognitions",
                      "Rewards and recognition programs to celebrate individual and team’s achievements"
                    );
                  }}
                >
                  <div className="image_container_joinus">
                    <img
                      src={medal_logo}
                      alt="JOIN US IMAGE"
                      className="joinus_sub_image"
                    />
                  </div>
                </div>
                <div
                  className="joinus_sub_circlefour"
                  onClick={() =>
                    handleSubCircleClick(
                      "Paid Time Off",
                      "36 days of annual leave."
                    )
                  }
                  onMouseOver={() => {
                    handleSubCircleClick(
                      "Paid Time Off",
                      "36 days of annual leave."
                    );
                  }}
                >
                  <div className="image_container_joinus">
                    <img
                      src={vacation_logo}
                      alt="JOIN US IMAGE"
                      className="joinus_sub_image"
                    />
                  </div>
                </div>
                <div
                  className="joinus_sub_circlefive"
                  onClick={() =>
                    handleSubCircleClick(
                      "Unlock your potential",
                      "Opportunity to work with Domain experts"
                    )
                  }
                  onMouseOver={() => {
                    handleSubCircleClick(
                      "Unlock your potential",
                      "Opportunity to work with Domain experts"
                    );
                  }}
                >
                  <div className="image_container_joinus">
                    <img
                      src={rewarded_logo}
                      alt="JOIN US IMAGE"
                      className="joinus_sub_image"
                    />
                  </div>
                </div>
                <div
                  className="joinus_sub_circlesix"
                  onClick={() =>
                    handleSubCircleClick(
                      "Medical Insurance",
                      "Comprehensive insurance plan covering health, accidental and life insurance."
                    )
                  }
                  onMouseOver={() => {
                    handleSubCircleClick(
                      "Medical Insurance",
                      "Comprehensive insurance plan covering health, accidental and life insurance."
                    );
                  }}
                >
                  <div className="image_container_joinus">
                    <img
                      src={care_logo}
                      alt="JOIN US IMAGE"
                      className="joinus_sub_image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
