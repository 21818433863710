import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Modal,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import {
  ModalStyle,
  validateField,
  VisuallyHiddenInput,
} from "../helper/common.utlis";
import "../styles/PopupModal.scss";
import { Oval } from "react-loader-spinner";
import { error } from "console";

type PopupModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const PopupModal: React.FC<PopupModalProps> = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    age: "",
    gender: "",
    isThesisSubmitted: "",
    yearOfSubmission: "",
    nameOfInstitution: "",
    placeOfInstitution: "",
    subject: "",
    topic: "",
    nameOfPhdSupervisor: "",
    emailOfPhdSupervisor: "",
    contributors: "",
    publicationDerived: "",
    file: "",
    initial: "",
  });
  const [fileError, setFileError] = useState<string>("");
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [alertMessage, setAlertMessage] = useState("");
  const [alertColor, setAlertColor] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const NAME_REGEX = /^[a-zA-Z\s]+$/;
  const EMAIL_REGEX =
    /^[a-zA-Z0-9]+([._]?[a-zA-Z0-9]+)*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const validateName = () => {
    const { name } = formData;
    let error = "";

    if (!name) {
      error = "Name is required.";
    } else if (!NAME_REGEX.test(name)) {
      error = "Name should only contain letters and spaces.";
    }

    setFormErrors((prev) => ({ ...prev, name: error }));
  };

  const validateEmail = () => {
    const { email } = formData;
    let error = "";

    if (!email) {
      error = "Email is required.";
    } else if (!EMAIL_REGEX.test(email)) {
      error = "Please enter a valid email address without special characters.";
    }

    setFormErrors((prev) => ({ ...prev, email: error }));
  };

  const handleChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;

    if (name === "age" && value.length > 2) return;
    if (name === "phoneNumber" && !/^\d*$/.test(value)) return;
    if (name === "name" && !NAME_REGEX.test(value) && value !== "") return;
    if (
      name === "nameOfPhdSupervisor" &&
      !NAME_REGEX.test(value) &&
      value !== ""
    )
      return;
    if (name === "nameOfInstitution" && !NAME_REGEX.test(value) && value !== "")
      return;

    setFormData({ ...formData, [name]: value });

    // Validation handling
    const validations: { [key: string]: () => void } = {
      phoneNumber: () => {
        if (value.length === 10 && !/^\d{10}$/.test(value)) {
          setFormErrors((prev) => ({
            ...prev,
            phoneNumber: "Mobile number must be exactly 10 digits.",
          }));
        } else {
          setFormErrors((prev) => ({ ...prev, phoneNumber: "" }));
        }
      },
      email: () => {
        if (!EMAIL_REGEX.test(value) && value !== "") {
          setFormErrors((prev) => ({
            ...prev,
            email: "Please check the mail address.",
          }));
        } else {
          setFormErrors((prev) => ({ ...prev, email: "" }));
        }
      },
      name: () => {
        if (!NAME_REGEX.test(value) && value !== "") {
          setFormErrors((prev) => ({
            ...prev,
            name: "Name should only contain letters and spaces.",
          }));
        } else {
          setFormErrors((prev) => ({ ...prev, name: "" }));
        }
      },
      nameOfPhdSupervisor: () => {
        if (!NAME_REGEX.test(value) && value !== "") {
          setFormErrors((prev) => ({
            ...prev,
            nameOfPhdSupervisor: "Name should only contain letters and spaces.",
          }));
        } else {
          setFormErrors((prev) => ({ ...prev, nameOfPhdSupervisor: "" }));
        }
      },
      nameOfInstitution: () => {
        if (!NAME_REGEX.test(value) && value !== "") {
          setFormErrors((prev) => ({
            ...prev,
            nameOfInstitution: "Name should only contain letters and spaces.",
          }));
        } else {
          setFormErrors((prev) => ({ ...prev, nameOfInstitution: "" }));
        }
      },
      emailOfPhdSupervisor: () => {
        if (!EMAIL_REGEX.test(value) && value !== "") {
          setFormErrors((prev) => ({
            ...prev,
            emailOfPhdSupervisor: "Please check the mail address.",
          }));
        } else {
          setFormErrors((prev) => ({ ...prev, emailOfPhdSupervisor: "" }));
        }
      },
    };

    if (validations[name]) {
      validations[name]();
    }
  };
  const validateMobile = () => {
    const { phoneNumber } = formData;
    let error = "";

    if (!/^\d{10}$/.test(phoneNumber)) {
      error = "Mobile number must be exactly 10 digits.";
    }

    setFormErrors((prev) => ({ ...prev, phoneNumber: error }));
  };

  const validateAge = () => {
    const { age } = formData;
    let error = "";

    if (!age || parseInt(age) < 18) {
      error = "Age must be at least 18.";
    }

    setFormErrors((prev) => ({ ...prev, age: error }));
  };

  const validateFile = () => {
    let error = "";

    if (selectedFiles.length === 0) {
      error = "Please upload at least one file.";
    }

    setFormErrors((prev) => ({ ...prev, file: error }));
    return error;
  };

  const validateForm = () => {
    const errors: { [key: string]: string } = {};

    Object.entries(formData).forEach(([key, value]) => {
      const error = validateField(key, value);
      if (error) {
        errors[key] = error;
      }
    });

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const submitFormData = async () => {
    if (!navigator.onLine) {
      setAlertMessage(
        "No internet connection. Please check your network and try again."
      );
      setAlertColor("red");
      return;
    }
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("email", formData.email);
      formDataToSend.append("name", formData.name);
      formDataToSend.append("phoneNumber", formData.phoneNumber);
      formDataToSend.append("age", formData.age);
      formDataToSend.append("project", "Scholarship");
      formDataToSend.append("gender", formData.gender);
      formDataToSend.append("isThesisSubmitted", formData.isThesisSubmitted);
      formDataToSend.append("yearOfSubmission", formData.yearOfSubmission);
      formDataToSend.append("nameOfInstitution", formData.nameOfInstitution);
      formDataToSend.append("placeOfInstitution", formData.placeOfInstitution);
      formDataToSend.append("subject", formData.subject);
      formDataToSend.append("topic", formData.topic);
      formDataToSend.append(
        "nameOfPhdSupervisor",
        formData.nameOfPhdSupervisor
      );
      formDataToSend.append(
        "emailOfPhdSupervisor",
        formData.emailOfPhdSupervisor
      );
      formDataToSend.append("contributors", formData.contributors);
      formDataToSend.append("publicationDerived", formData.publicationDerived);
      formDataToSend.append("initial", formData.initial);
      selectedFiles.forEach((file: any) => {
        formDataToSend.append("file", file);
      });
      const response = await fetch(
        "https://stage-socialmedia-scrapping-be.azurewebsites.net/api/mailtrigger?code=J-I9R21Xi4gitRbLJYaMs2QnIAC6MlBFHVdi0dBk0xeyAzFubdXf8A%3D%3D",
        {
          method: "POST",
          body: formDataToSend,
        }
      );

      const data = await response.json();
      if (data.status_code === 200) {
        setAlertMessage(data.content);
        setAlertColor("green");
      } else {
        setAlertMessage(data.content);
        setAlertColor("red");
      }
    } catch (error: any) {
      console.error(error);
      setAlertMessage("Failed to submit application");
      setAlertColor("red");
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    setAlertMessage("");
    setAlertColor("red");

    if (validateForm() && !validateFile()) {
      await submitFormData();
    }

    setIsLoading(false);
  };
  if (!isOpen) return null;

  const handleFileChange = (event: any) => {
    const newFiles = Array.from(event.target.files);

    if (newFiles.length === 0) return;

    const totalFiles = [...selectedFiles, ...newFiles];
    if (totalFiles.length > 2) {
      alert("You can only upload a maximum of 2 files.");
      return;
    }

    const totalSize = totalFiles.reduce((sum, file: any) => sum + file.size, 0);

    const validNewFiles = newFiles.filter((file: any) => {
      const isValidType = file.type === "application/pdf";
      return isValidType && totalSize <= 20 * 1024 * 1024;
    });

    if (validNewFiles.length < newFiles.length) {
      alert("Maximum file size exceeded.");
    }

    setSelectedFiles([...selectedFiles, ...validNewFiles]);
  };

  const onModalClose = () => {
    setFormData({
      name: "",
      email: "",
      phoneNumber: "",
      age: "",
      gender: "",
      isThesisSubmitted: "",
      yearOfSubmission: "",
      nameOfInstitution: "",
      placeOfInstitution: "",
      subject: "",
      topic: "",
      nameOfPhdSupervisor: "",
      emailOfPhdSupervisor: "",
      contributors: "",
      publicationDerived: "",
      file: "",
      initial: "",
    });
    setFormErrors({});
    setAlertMessage("");
    setAlertColor("");
    setSelectedFiles([]);
    onClose();
  };

  const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9- ]+$/;

  return (
    <Modal
      open={true}
      onClose={() => {
        onModalClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      title="Application Form"
    >
      <Box sx={ModalStyle}>
        <div
          className="close-icon"
          onClick={() => {
            onModalClose();
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
          </svg>
        </div>
        <div
          className="modal-container"
          style={{ paddingBottom: "2rem !important" }}
        >
          <h2 className="modal-header">Application Form</h2>
          <form onSubmit={handleSubmit}>
            <TextField
              label={
                <>
                  Name <span style={{ color: "red" }}>*</span>
                </>
              }
              name="name"
              type="text"
              value={formData.name}
              onChange={handleChange}
              error={!!formErrors.name}
              helperText={formErrors.name}
              fullWidth
              margin="normal"
              onBlur={validateName}
            />
            <TextField
              label={
                <>
                  Email <span style={{ color: "red" }}>*</span>
                </>
              }
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              error={!!formErrors.email}
              helperText={formErrors.email}
              fullWidth
              margin="normal"
              onBlur={validateEmail}
            />

            <TextField
              label={
                <>
                  Mobile Number <span style={{ color: "red" }}>*</span>
                </>
              }
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              error={!!formErrors.phoneNumber}
              helperText={formErrors.phoneNumber}
              fullWidth
              margin="normal"
              inputProps={{
                maxLength: 10,
                inputMode: "numeric",
                pattern: "[0-9]*",
              }}
              onBlur={validateMobile}
            />
            <TextField
              label={
                <>
                  Age <span style={{ color: "red" }}>*</span>
                </>
              }
              name="age"
              type="number"
              value={formData.age}
              onChange={handleChange}
              error={!!formErrors.age}
              helperText={formErrors.age}
              fullWidth
              margin="normal"
              inputProps={{ min: 18 }}
              onBlur={validateAge}
            />

            <div className="form-container">
              <FormControl
                component="fieldset"
                className="form-group"
                error={!!formErrors.gender}
              >
                <FormLabel
                  component="legend"
                  id="gender-group-label"
                  error={!!formErrors.gender}
                >
                  Gender <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <RadioGroup
                  aria-labelledby="gender-group-label"
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="female"
                    control={<Radio />}
                    label="Female"
                  />
                  <FormControlLabel
                    value="male"
                    control={<Radio />}
                    label="Male"
                  />
                  <FormControlLabel
                    value="other"
                    control={<Radio />}
                    label="Other"
                  />
                </RadioGroup>
              </FormControl>

              <FormControl component="fieldset" className="form-group">
                <FormLabel
                  component="legend"
                  id="qualification-group-label"
                  error={!!formErrors.isThesisSubmitted}
                >
                  Have you submitted your Ph.D. Thesis?{" "}
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <RadioGroup
                  aria-labelledby="qualification-group-label"
                  name="isThesisSubmitted"
                  value={formData.isThesisSubmitted}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </div>

            <TextField
              label={
                <>
                  If yes, Date of Submission{" "}
                  <span style={{ color: "red" }}>*</span>
                </>
              }
              name="yearOfSubmission"
              type="date"
              value={formData.yearOfSubmission}
              onChange={handleChange}
              error={!!formErrors.yearOfSubmission}
              helperText={formErrors.yearOfSubmission}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                max: new Date().toISOString().split("T")[0],
              }}
            />

            <TextField
              label={
                <>
                  Name of the Institution{" "}
                  <span style={{ color: "red" }}>*</span>
                </>
              }
              name="nameOfInstitution"
              value={formData.nameOfInstitution}
              onChange={handleChange}
              error={!!formErrors.nameOfInstitution}
              helperText={formErrors.nameOfInstitution}
              fullWidth
              margin="normal"
              onKeyDown={(event) => {
                if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />

            <div className="form-container">
              <FormControl component="fieldset" className="form-group">
                <FormLabel
                  component="legend"
                  id="placeofInstitution-group-label"
                  error={!!formErrors.isThesisSubmitted}
                >
                  Institution's Location <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <RadioGroup
                  aria-labelledby="placeofInstitution-group-label"
                  name="placeOfInstitution"
                  value={formData.placeOfInstitution}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="Tamil Nadu"
                    control={<Radio />}
                    label="Tamil Nadu"
                  />
                  <FormControlLabel
                    value="Puducherry"
                    control={<Radio />}
                    label="Puducherry"
                  />
                </RadioGroup>
              </FormControl>

              <FormControl component="fieldset" className="form-group">
                <FormLabel
                  component="legend"
                  id="Subject-group-label"
                  error={!!formErrors.isThesisSubmitted}
                >
                  Subject <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <RadioGroup
                  aria-labelledby="Subject-group-label"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="Social"
                    control={<Radio />}
                    label="Social Sciences"
                  />
                  <FormControlLabel
                    value="Studies"
                    control={<Radio />}
                    label="Gender Studies"
                  />
                  <FormControlLabel
                    value="Development"
                    control={<Radio />}
                    label="Youth Development"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <TextField
              label={
                <>
                  Topic of your Thesis <span style={{ color: "red" }}>*</span>
                </>
              }
              id="Thesis"
              name="topic"
              value={formData.topic}
              onChange={handleChange}
              error={!!formErrors.topic}
              helperText={formErrors.topic}
              margin="normal"
              fullWidth
              onKeyDown={(event) => {
                if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />

            <TextField
              label={
                <>
                  Name of the Ph.D. Supervisor{" "}
                  <span style={{ color: "red" }}>*</span>
                </>
              }
              name="nameOfPhdSupervisor"
              id="nameOfPhdSupervisor"
              value={formData.nameOfPhdSupervisor}
              onChange={handleChange}
              error={!!formErrors.nameOfPhdSupervisor}
              helperText={formErrors.nameOfPhdSupervisor}
              margin="normal"
              fullWidth
              onKeyDown={(event) => {
                if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />

            <TextField
              label={
                <>
                  Email id of the Ph.D. Supervisor{" "}
                  <span style={{ color: "red" }}>*</span>
                </>
              }
              name="emailOfPhdSupervisor"
              type="emailOfPhdSupervisor"
              onChange={handleChange}
              value={formData.emailOfPhdSupervisor}
              error={!!formErrors.emailOfPhdSupervisor}
              helperText={formErrors.emailOfPhdSupervisor}
              fullWidth
              margin="normal"
              onBlur={validateEmail}
            />
            <TextField
              label={
                <>
                  Key Research Contributions{" "}
                  <span style={{ color: "red" }}>*</span>
                </>
              }
              id="contributors"
              name="contributors"
              value={formData.contributors}
              onChange={handleChange}
              error={!!formErrors.contributors}
              helperText={formErrors.contributors}
              margin="normal"
              onKeyDown={(event) => {
                if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                  event.preventDefault();
                }
              }}
              fullWidth
            />
            <TextField
              label={
                <>
                  Publications derived from this Thesis Work{" "}
                  <span style={{ color: "red" }}>*</span>
                </>
              }
              id="publicationDerived"
              name="publicationDerived"
              value={formData.publicationDerived}
              onChange={handleChange}
              error={!!formErrors.publicationDerived}
              helperText={formErrors.publicationDerived}
              margin="normal"
              fullWidth
              onKeyDown={(event) => {
                if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
            <div className="upload-button-container">
              <Button
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
              >
                Upload Thesis
                <VisuallyHiddenInput
                  type="file"
                  onChange={handleFileChange}
                  multiple
                  accept=".pdf"
                  required
                />
              </Button>
              (Upload up to 2 PDFs with total size limit of 20MB)
              <span style={{ color: "red" }}>*</span>
              {selectedFiles.length > 0 && (
                <div>
                  <h4>Selected files:</h4>
                  <ul>
                    {selectedFiles.map((file: any, index) => (
                      <li key={index}>
                        {file.name}
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            const newFiles = selectedFiles.filter(
                              (_, i) => i !== index
                            );
                            setSelectedFiles(newFiles);
                          }}
                          style={{
                            marginLeft: "10px",
                            border: "none",
                            background: "none",
                            color: "red",
                            cursor: "pointer",
                          }}
                        >
                          ✕
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {formErrors.file && (
                <p style={{ color: "red" }}>{formErrors.file}</p>
              )}
            </div>

            <TextField
              label={
                <>
                  I agree that the Thesis is my original work and can be
                  evaluated for PEN Scholar Awards. (Please enter your initials
                  as a form of consent.) <span style={{ color: "red" }}>*</span>
                </>
              }
              name="initial"
              value={formData.initial}
              onChange={handleChange}
              error={!!formErrors.initial}
              helperText={formErrors.initial}
              margin="normal"
              fullWidth
            />

            {alertMessage && (
              <div style={{ color: alertColor }}>{alertMessage}</div>
            )}
            <div className="button-group">
              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "1rem 0",
                  }}
                >
                  <Oval
                    height={50}
                    width={50}
                    color="#4fa94d"
                    ariaLabel="oval-loading"
                    secondaryColor="#4fa94d"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                </div>
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="submit-button"
                  disabled={isLoading}
                >
                  Submit
                </Button>
              )}
            </div>
          </form>
        </div>
      </Box>
    </Modal>
  );
};

export default PopupModal;
