import React from "react";
import "../styles/AcknowledgePage.scss";
import acknowledge from "../assets/Acknowledge.png";

function AcknowledgePage() {
  return (
    <div className="acknowledge-container">
      <div className="acknowledge-details ">
        <div>
          Acknowledge the important contributions to the knowledge base in the
          respective area of study.
        </div>
        <div>
          Recognize enhancements or advancements on the existing knowledge.
        </div>
        <div>
          Promote academic excellence and elevate the standard of academic
          research in Tamil Nadu.
        </div>
      </div>
      <div className="acknowledge-img">
        <img src={acknowledge} alt="acknowledge" />
      </div>
    </div>
  );
}

export default AcknowledgePage;
